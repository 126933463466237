* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: rgba(250, 242, 242, 0);
}

/* Containers */
.page-container {
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-container {
  min-height: 45vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../images/swamp.jpg");
  background-size: cover;
  background-position: center;
  margin-bottom: 2rem;
}

.info-container {
  min-height: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  line-height: 1.5;
  letter-spacing: 1.1px;
  padding: 16px;
  margin-top: -5px;
  color: rgb(0, 0, 0);
}

.info-box {
  background-color: rgba(0, 0, 0, 0.055);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 10px;
  min-width: 300px;
  max-width: 699px;
  padding: 25px;
  text-align: left;
}

/* Typography*/

.title {
  font-family: "Charu Chandan BloodDrip Unicode";
  font-size: 45px;
  color: white;
  text-shadow: 2px 8px 8px rgba(0, 0, 0, 1);
  font-weight: 100;
}

.info-title {
  font-family: "Charu Chandan BloodDrip Unicode";
  font-size: 45px;
  text-align: center;
  margin-bottom: 1rem;
}
.p-1 {
  font-size: 24px;
}

/* email and buttons */

.email-input {
  height: 40px;
  width: 200px;
  padding: 0.5rem;
  margin: 8px 24px 8px 0;
  border: none;
}

.sign-up_btn {
  padding: 8px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  height: 40px;
  width: 75px;
  font-size: 16px;
}
@media (min-width: 280px) {
  .title {
    font-size: 30px;
  }
}
@media (min-width: 375px) {
  .title {
    font-size: 42px;
  }
  .p-1 {
    font-size: 28px;
  }
  .info-box {
    margin-top: -20px;
  }
}
@media (min-width: 600px) {
  .title {
    font-size: 70px;
  }

  .info-title {
    font-size: 50px;
  }
  .p-1 {
    font-size: 25px;
  }
}

@media (min-width: 900px) {
  .hero-container {
    background-image: none;
  }
  .page-container {
    background-image: url("../images/swamp.jpg");
    background-size: cover;
    background-position: center;
    height: 100vh;
  }
  .title {
    font-size: 100px;
  }
  .info-box {
    background-color: rgba(0, 0, 0, 0.575);
    margin-bottom: 3rem;
    max-height: 400px;
    text-align: center;
  }
  .info-container {
    color: white;
  }
  .info-title {
    font-size: 40px;
  }
  p {
    font-size: 32px;
  }
}

@media (min-width: 1200px) {
  .title {
    font-size: 135px;
    letter-spacing: 0.1px;
  }
  .info-box {
    max-width: 800px;
  }
  .p-1 {
    font-size: 40px;
  }
}

/* Height media queries */

@media (min-height: 900px) {
  .info-title {
    font-size: 50px;
  }
  .info-box {
    padding: 30px;
  }
  .p-1 {
    font-size: 30px;
  }
}

@media (min-height: 1100px) {
  .title {
    font-size: 90px;
  }
  .info-title {
    font-size: 80px;
  }
  .info-box {
    padding: 30px;
  }
  .p-1 {
    font-size: 45px;
  }
}
